import React from 'react';
import { Client } from 'boardgame.io/react';
import TicTacToe from './game';
import BaseBoard from './board';

function AI(G) {
  if (G.cells[4] === ' ') {
    return 4;
  }

  const positions = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6]
  ];

  for (let pos of positions) {
    let count = 0;
    let empty = null;

    for (let i of pos) {
      if (G.cells[i] === 'O') {
        count++;
      }
      if (G.cells[i] === ' ') {
        empty = i;
      }
    }

    if (count === 2 && empty !== null) {
      return empty;
    }
  }

  let empty = [];
  for (let i = 0; i < 9; i++) {
    if (G.cells[i] === ' ') {
      empty.push(i);
    }
  }
  return empty[Math.floor(Math.random() * empty.length)];
}

class Board extends BaseBoard {
  componentDidUpdate(prevProps) {
    if (prevProps.G !== this.props.G &&
        this.props.ctx.currentPlayer === '1') {
      setTimeout(() => {
        const id = AI(this.props.G);
        this.props.moves.clickCell(id);
      }, 200);
    }
  }
}

export default Client({
  game: TicTacToe,
  board: Board,
  debug: false,
});
