import React from 'react';
import { Client } from 'boardgame.io/react';
import { IsVictory, IsTerminal } from './utils';
import TicTacToe from './game';
import BaseBoard from './board';

function AI(G) {
  let cache = {};

  function find(state, maximize, player) {
    if (state in cache) {
      return cache[state];
    }

    let r = 0;

    const victory = IsVictory(state);
    if (victory !== undefined) {
      r = victory.winner === player ? 1 : -1;
    } else if (IsTerminal(state)) {
      r = 0;
    } else {
      let next = [];
      for (let i = 0; i < state.length; i++) {
        if (state[i] === ' ') {
          let p = maximize ? 'X' : 'O';
          let t = state.slice(0, i) + p + state.slice(i + 1);
          next.push(find(t, !maximize, player));
        }
      }

      if (maximize) {
        r = Math.max(...next);
      } else {
        r = Math.min(...next);
      }
    }

    cache[state] = r;
    return r;
  }

  find(G.cells, true, 'X');

  let best = null;
  let bestMove = null;
  let state = G.cells;
  for (let i = 0; i < 9; i++) {
    if (state[i] === ' ') {
      let next = state.slice(0, i) + 'X' + state.slice(i + 1);
      if (cache[next] > best || best === null) {
        best = cache[next];
        bestMove = i;
      }
    }
  }

  return bestMove;
}

class Board extends BaseBoard {
  componentDidUpdate(prevProps) {
    if (prevProps.G !== this.props.G &&
        this.props.ctx.currentPlayer === '1') {
      setTimeout(() => {
        const id = AI(this.props.G);
        this.props.moves.clickCell(id);
      }, 200);
    }
  }
}

export default Client({
  game: TicTacToe,
  board: Board,
  debug: false,
});
