import { Game } from 'boardgame.io/core';
import { IsVictory, IsTerminal } from './utils';

const TicTacToe = Game({
  name: 'tic-tac-toe',

  setup: () => ({
    cells: '         ',
  }),

  moves: {
    clickCell(G, ctx, id) {
      if (G.cells[id] === ' ') {
        let t = G.cells.slice(0, id);
        t += ctx.currentPlayer == '0' ? 'O' : 'X';
        t += G.cells.slice(id + 1);
        G.cells = t;
      }
    }
  },

  flow: {
    movesPerTurn: 1,

    endGameIf: (G, ctx) => {
      const v = IsVictory(G.cells);

      if (v !== undefined) {
        return v;
      }

      if (IsTerminal(G.cells)) {
        return { draw: true };
      }
    }
  }
});

export default TicTacToe;
