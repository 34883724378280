import React from 'react';
import PropTypes from 'prop-types';
import { IsVictory, IsTerminal } from './utils';
import './board.css';

export class Board extends React.Component {
  onClick = id => {
    if (this.isActive(id)) {
      this.props.moves.clickCell(id);
    }
  }

  reset = () => {
    this.props.reset();
  }

  isActive(id) {
    if (!this.props.isActive) return false;
    if (this.props.ctx.currentPlayer !== '0') return false;
    if (this.props.G.cells[id] !== ' ') return false;
    return true;
  }

  render() {
    const { G, ctx, highlight, dark } = this.props;

    let tbody = [];
    for (let i = 0; i < 3; i++) {
      let cells = [];
      for (let j = 0; j < 3; j++) {
        const id = 3 * i + j;

        let className = 'cell';
        if ((highlight && highlight.includes(id)) ||
            ctx &&
            ctx.gameover &&
            ctx.gameover.victory &&
            ctx.gameover.victory.includes(id)) {
          className += ' highlight';
        }

        if (this.isActive(id)) {
          className += ' active';
        }

        cells.push(
          <td
            key={id}
            className={className}
            onClick={() => this.onClick(id)}
          >
            {G.cells[id]}
          </td>
        );
      }
      tbody.push(<tr key={i}>{cells}</tr>);
    }

    let boardClass = 'board';
    let screen = null;
    if (ctx && ctx.gameover) {
      boardClass += ' fade';
      screen = (
        <div className="screen">
          <button onClick={this.reset}>Play Again</button>
        </div>
      );
    }

    if (dark) {
      boardClass += ' dark';
    }

    let message = ' ';
    if (ctx === undefined && this.props.score === undefined) {
      const v = IsVictory(G.cells);
      if (v !== undefined) {
        message = v.winner + ' wins!';
      } else if (IsTerminal(G.cells)) {
        message = 'Draw';
      }
    }

    let score = null;
    if (this.props.score !== undefined) {
      let className = 'score';
      if (this.props.score === '+1') {
        className += ' green';
      }
      if (this.props.highlightScore) {
        className += ' black';
      }
      score = <div className={className}>{this.props.score}</div>;
    }

    return (
      <div className="tic-tac-toe">
        <table className={boardClass}>
          <tbody>{tbody}</tbody>
        </table>
        <div className="message">{message}</div>
        {score}
        {screen}
      </div>
    );
  }
}

export default Board;
