import React from 'react';
import { Board } from './board';

class NodeLabel extends React.PureComponent {
  render() {
    const {nodeData} = this.props;
    const G = { cells: nodeData.cells || '         '};
    let score = nodeData.score;
    
    if (score === undefined && nodeData.n !== undefined) {
      score = `${nodeData.w}/${nodeData.n}`;
    }

    return (
      <div className="node">
        <Board G={G}
               {...nodeData}
               score={score}/>
      </div>
    )
  }
}

export default class Visualization extends React.Component {
  constructor(props) {
    super(props);
    this.state = { clientSide: false, dimensionsUpdated: false };
    this.el = React.createRef();
  }

  updateDimensions() {
    const dimensions = this.el.current.getBoundingClientRect();
    this.setState({
      dimensionsUpdated: true,
      translate: {
        x: dimensions.width / 2,
        y: 50,
      }
    });
  }

  componentDidMount() {
    this.setState({ clientSide: true });
  }

  componentDidUpdate() {
    if (this.el.current && !this.state.dimensionsUpdated) {
      this.updateDimensions();
    }
  }

  render() {
    if (!this.state.clientSide) {
      return <div className="d3"></div>;
    }

    const Tree = require('react-d3-tree').Tree;

    const {nodes} = this.props;
    let transitionDuration = 500;
    if (this.props.disableAnimation) {
      transitionDuration = 0;
    }

    return (
      <div className="d3" ref={this.el}>
        <Tree data={nodes}
              orientation="vertical"
              zoomable={false}
              allowForeignObjects={true}
              nodeSvgShape={{ shape: 'none' }}
              transitionDuration={transitionDuration}
              nodeLabelComponent={{
                render: <NodeLabel/>,
                foreignObjectWrapper: {
                  width: 160,
                  height: 220,
                  y: -80,
                  x: -80
                },
              }}
              separation={{
                siblings: 1.5,
                nonSiblings: 1.5,
              }}
              depthFactor={300.0}
              zoom={.5}
              translate={this.state.translate}
        />
      </div>
    );
  }
}
