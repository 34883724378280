import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/templates/blog-post.js"
import ExampleRuleBased from '../../components/tic-tac-toe/example-rule-based'
import Visualization from '../../components/tic-tac-toe/visualization'
import { Board } from '../../components/tic-tac-toe/board'
import ExampleMinimax from '../../components/tic-tac-toe/example-minimax'
import { Ni, Np, Wi, UCT, Description } from '../../components/tic-tac-toe/equations'
import MCTS from '../../components/tic-tac-toe/mcts-visualization.js'

export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>

<MDXTag name="p" components={components}>{`This post features different strategies to implement
a bot for a turn-based game like Tic-Tac-Toe. Some are pragmatic
while others are overkill for simple games like Tic-Tac-Toe.
The point is to give you a taste of some techniques that you can
then apply to more elaborate games.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Rule-Based Heuristics`}</MDXTag>
<MDXTag name="p" components={components}>{`It’s always tempting to create a bot that’s governed by
predictable rules. These rules come from knowledge of game
strategy and loosely follow how a human player thinks while
playing the game.`}</MDXTag>
<MDXTag name="p" components={components}>{`Examples of rules that make sense in Tic-Tac-Toe:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Start in the center square.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`If the opponent has two in a row, perform a block.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`At each turn the bot checks against these rules and plays
accordingly whenever a rule fires. It falls back to a random
move when no rule is applicable on a particular turn.`}</MDXTag>
<MDXTag name="p" components={components}>{`Try playing against this bot and see how it fares:`}</MDXTag>

<ExampleRuleBased/>
<MDXTag name="p" components={components}>{`You’ll notice that it isn’t terrible, but it can still be beaten.
It also does some silly things like not finishing off a game
even when it has two in a row because we didn’t explicitly
instruct it to do so. We can continue strengthening this bot by adding more rules, but let’s explore some techniques that don’t
require as much domain knowledge instead.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Tree Searches`}</MDXTag>
<MDXTag name="p" components={components}>{`The previous approach is beatable because the bot doesn’t
look ahead and consider all the future ramifications of making a particular
move. This is solved by the following techniques which simulate
the game a number of moves ahead and then pick a move that is likely
to result in victory.`}</MDXTag>
<MDXTag name="p" components={components}>{`The current game state and all the future ones that can be
reached form a directed graph called a game tree. Each game state
is a node in the tree and each edge represents a move that takes
the game from the source node to its child. The current game
state sits at the root of this tree.`}</MDXTag>
<MDXTag name="h4" components={components}>{`Minimax`}</MDXTag>

<MDXTag name="p" components={components}>{`Let’s say we’re in this state and it is X’s turn to play:`}</MDXTag>
<Board G={{ cells: 'OOXX O  X' }} />
<MDXTag name="p" components={components}>{`If we explore all possible moves from this point forward,
we get the following game tree. At this point we try to
determine which of the three branches to take.`}</MDXTag>
<Visualization nodes={[
  { cells: 'OOXX O  X', children: [
    { cells: 'OOXXXO  X', highlight: [4], children: [
      {cells: 'OOXXXO OX', highlight: [7], children: [
        {cells: 'OOXXXOXOX', highlight: [6]}
      ]},
      {cells: 'OOXXXOO X', highlight: [6], children: [
        {cells: 'OOXXXOOXX', highlight: [7]}
      ]},
    ]},
    { cells: 'OOXX OX X', highlight: [6], children: [
      {cells: 'OOXXOOX X', highlight: [4], children: [
        {cells: 'OOXXOOXXX', highlight: [7]}
      ]},
      {cells: 'OOXX OXOX', highlight: [7], children: [
        {cells: 'OOXXXOXOX', highlight: [4]}
      ]},
    ]},
    { cells: 'OOXX O XX', highlight: [7], children: [
      {cells: 'OOXX OOXX', highlight: [6], children: [
        {cells: 'OOXXXOOXX', highlight: [4]}
      ]},
      {cells: 'OOXXOO XX', highlight: [4], children: [
        {cells: 'OOXXOOXXX', highlight: [6]}
      ]},
    ]},
  ]},
]} />
<MDXTag name="p" components={components}>{`Minimax will ensure that we pick the middle branch so that
X is guaranteed to win. It does so by assigning a score to each
node in the game tree that represents the benefit to X:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`A game state that results in X winning is assigned a score of `}<MDXTag name="strong" components={components} parentName="li">{`+1`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`A game state that results in X losing is assigned a score of `}<MDXTag name="strong" components={components} parentName="li">{`-1`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Draw states are assigned a score of `}<MDXTag name="strong" components={components} parentName="li">{`0`}</MDXTag>{`.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`The intermediate nodes are scored depending on whose turn it is
at that node:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`If it is X, we pick the the child
with the highest score and assign that to the node
(X picks a move that `}<MDXTag name="em" components={components} parentName="p">{`maximizes`}</MDXTag>{` its score).`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`If it is O, we do the opposite and pick the child with
the least score. The intuition here is that O will play
optimally and pick a move that results in the least benefit
to X (i.e. it `}<MDXTag name="em" components={components} parentName="p">{`minimizes`}</MDXTag>{` the score).`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`The roles of X and O are swapped on the next turn when O
becomes the current player (O becomes the `}<MDXTag name="em" components={components} parentName="p">{`maximizer`}</MDXTag>{` and
X becomes the `}<MDXTag name="em" components={components} parentName="p">{`minimizer`}</MDXTag>{`).`}</MDXTag>
<MDXTag name="p" components={components}>{`Here are the scores for the game tree that we just looked at:`}</MDXTag>
<Visualization nodes={[
  { cells: 'OOXX O  X', children: [
    { cells: 'OOXXXO  X', highlight: [4], score: '0', children: [
      {cells: 'OOXXXO OX', highlight: [7], score: '+1', children: [
        {cells: 'OOXXXOXOX', score: '+1', highlight: [6]}
      ]},
      {cells: 'OOXXXOO X', highlight: [6], score: '0', children: [
        {cells: 'OOXXXOOXX', highlight: [7], score: '0'}
      ]},
    ]},
    { cells: 'OOXX OX X', highlight: [6], score: '+1', children: [
      {cells: 'OOXXOOX X', highlight: [4], score: '+1', children: [
        {cells: 'OOXXOOXXX', highlight: [7], score: '+1'}
      ]},
      {cells: 'OOXX OXOX', highlight: [7], score: '+1', children: [
        {cells: 'OOXXXOXOX', highlight: [4], score: '+1'}
      ]},
    ]},
    { cells: 'OOXX O XX', highlight: [7], score: '0', children: [
      {cells: 'OOXX OOXX', highlight: [6], score: '0', children: [
        {cells: 'OOXXXOOXX', highlight: [4], score: '0'}
      ]},
      {cells: 'OOXXOO XX', highlight: [4], score: '+1', children: [
        {cells: 'OOXXOOXXX', highlight: [6], score: '+1'}
      ]},
    ]},
  ]},
]} />
<MDXTag name="p" components={components}>{`Once the three nodes at the top are scored, we can just
pick the node with the highest score as the next move.`}</MDXTag>
<MDXTag name="p" components={components}>{`You can try playing against the following bot that uses
Minimax. It cannot be beaten.`}</MDXTag>

<ExampleMinimax/>
<MDXTag name="p" components={components}>{`In games with large search spaces, optimizations like `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://en.wikipedia.org/wiki/Alpha%E2%80%93beta_pruning"}}>{`Alpha-Beta Pruning`}</MDXTag>{`
come in handy to avoid searching down every single path in
the game tree.`}</MDXTag>
<MDXTag name="h4" components={components}>{`Monte Carlo Tree Search (MCTS)`}</MDXTag>
<MDXTag name="p" components={components}>{`MCTS is another approach that searches the game tree
before deciding which move to pick next. However, it works
quite differently from Minimax.`}</MDXTag>
<MDXTag name="p" components={components}>{`For one, it does not exhaustively search the game tree. Rather,
it samples different paths randomly and then
builds up statistics that inform it which moves work well. As
these statistics build up, the algorithm continues to exploit
moves that are known to work well in order to probe them
further.`}</MDXTag>
<MDXTag name="p" components={components}>{`This is balanced by some amount of exploration of other paths
so that the algorithm doesn’t get stuck down one or two paths
that are yielding good results so far. It is this balance of `}<MDXTag name="em" components={components} parentName="p">{`exploration`}</MDXTag>{` vs. `}<MDXTag name="em" components={components} parentName="p">{`exploitation`}</MDXTag>{` that makes
MCTS a great choice for game trees that have huge branching
factors and are too large to search exhaustively. It can also
be applied to games with more than two players.`}</MDXTag>
<MDXTag name="p" components={components}>{`Another advantage of MCTS is that we can control the number
of iterations, effectively creating a tuneable bot
that is stronger the more time you give it per turn. The bot
can be stopped at any point and it will still suggest
a move to play.`}</MDXTag>
<MDXTag name="p" components={components}>{`It is important to reiterate that MCTS doesn’t explore the
entire game tree. Rather, it maintains a parallel tree of
statistics that it expands repeatedly. Each node in this
tree corresponds to a single node in the game tree.`}</MDXTag>
<MDXTag name="p" components={components}>{`Here is what we store at each node:`}</MDXTag>

<Description/>
<MDXTag name="p" components={components}>{`It executes the following four steps repeatedly until we tell
it to stop:`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1. Selection`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`This phase starts at the root node and keeps follows
nodes down the tree until it finds a node that is either a
terminal, or a node that hasn’t fully been expanded (i.e.
all its children haven’t been added to the MCTS tree yet).`}</MDXTag>
<MDXTag name="p" components={components}>{`The child selection policy at each level of the tree
must ensure that we visit promising paths often while also
ensuring that we don’t neglect other children.`}</MDXTag>
<MDXTag name="p" components={components}>{`A popular algorithm to do this is the `}<MDXTag name="strong" components={components} parentName="p">{`Upper Confidence Bound (UCT)`}</MDXTag>{`.
It boils down to calculating the following value for each
child `}<MDXTag name="em" components={components} parentName="p">{`i`}</MDXTag>{` and then picking the child that has the highest
`}<MDXTag name="strong" components={components} parentName="p">{`UCT`}</MDXTag>{` value:`}</MDXTag>
<UCT/>
<MDXTag name="p" components={components}>{`The first term ensures `}<MDXTag name="em" components={components} parentName="p">{`exploitation`}</MDXTag>{` (as `}<Wi/>{` grows the
UCT value will also grow) and the second term
ensures `}<MDXTag name="em" components={components} parentName="p">{`exploration`}</MDXTag>{` (as we’ll see later, `}<Np/>{` grows
every time one of its children is visited, so this term
grows for every child that is not visited during a cycle).`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2. Expansion`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Once we’ve selected a node, we randomly add one of its children to
the MCTS tree.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3. Playout`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`We now simulate the game all the way to the end starting
at the node we picked in the `}<MDXTag name="strong" components={components} parentName="p">{`Expansion`}</MDXTag>{` phase. Note that none of the game states
visited during this simulation are actually added to the MCTS
tree. We just use the result of the simulation to update
the statistics in the tree.`}</MDXTag>
<MDXTag name="p" components={components}>{`This phase can just choose moves at random, but we will
get better results if we choose plausible moves using some
heuristics like the ones described at the beginning of this post.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4. Backpropagation`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`This phase propagates the result of the simulation back up
the tree all the way to the root. We increment `}<Ni/>{` at
each node on the path from the root to the `}<MDXTag name="strong" components={components} parentName="p">{`Playout`}</MDXTag>{` node.
We also increment `}<Wi/>{`, but only if the simulation
resulted in a win for the player that just moved at that
node.`}</MDXTag>
<MDXTag name="p" components={components}>{`Once we’ve accumulated enough statistics (either by checking
for convergence or just running the algorithm for a fixed
number of iterations), we pick the child of the root that
has been visited the most as the next move. This is the node
with the highest `}<Ni/>{` among its siblings.`}</MDXTag>
<MDXTag name="p" components={components}>{`Enough talk, now see the algorithm in action. Click `}<MDXTag name="em" components={components} parentName="p">{`step`}</MDXTag>{`
repeatedly to step through the algorithm. You’ll notice that
it converges to the result we achieved through Minimax
after some iterations. The numbers at each node are `}<Wi/>{` / `}<Ni/>{`.`}</MDXTag>

<MCTS/>
<MDXTag name="h3" components={components}>{`Other Approaches`}</MDXTag>
<MDXTag name="h4" components={components}>{`Solving the game`}</MDXTag>
<MDXTag name="p" components={components}>{`The keen observer will note that Tic-Tac-Toe doesn’t actually have
that many game states. A lazy upper bound can be calculated as
3^9 = 19683 (each square can either be empty, contain an X or an O)
which is quite small. The actual number is even smaller
because we also end up counting states that are impossible
during play (a grid filled with X’s for example).
We can reduce this even further by grouping together states that share rotational symmetry or are mirror images of each other.`}</MDXTag>
<MDXTag name="p" components={components}>{`So, in theory we can precompute
the best move at each game state (using an approach like
Minimax) and then store it in a lookup table. This results in a
fast (and predictable) bot that is unbeatable.`}</MDXTag>
<MDXTag name="p" components={components}>{`How we choose to encode the game state affects how big this
lookup table will be. One approach would be to represent the
board as a ternary number.`}</MDXTag>
<MDXTag name="p" components={components}>{`If we use the following digit positions:`}</MDXTag>
<Board G={{ cells: '012345678' }} ctx={{}} />
<MDXTag name="p" components={components}>{`and encode X as 1, O as 0, and an empty cell as 2, then the game state:`}</MDXTag>
<Board G={{ cells: 'XOOXX XO ' }} ctx={{}} />
<MDXTag name="p" components={components}>{`gets encoded as the ternary number 201211001, which is 14446
in base-10. This can be stored in an integer.`}</MDXTag>
<MDXTag name="h4" components={components}>{`Learning from human players`}</MDXTag>
<MDXTag name="p" components={components}>{`Bots that get stronger over repeated play sessions are probably
the most interesting of the lot. In the previous section we
computed the lookup table by using a tree search algorithm, but
there’s no reason that you can’t populate it with moves made by
actual human players!`}</MDXTag>
<MDXTag name="p" components={components}>{`Even a naive approach of just picking the most frequent move that
humans make at a particular game state can lead to an interesting
bot that “learns” how to play by observing.`}</MDXTag>
<MDXTag name="p" components={components}>{`Note that this is different from machine learning techniques like
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://en.wikipedia.org/wiki/Reinforcement_learning"}}>{`Reinforcement Learning`}</MDXTag>{`, which is a topic for another post.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Tic-Tac-Toe examples were built using `}<MDXTag name="a" components={components} parentName="em" props={{"href":"https://boardgame.io"}}>{`boardgame.io`}</MDXTag></MDXTag>{`.`}</MDXTag>
           </MDXTag>
  }
}

export const _frontmatter = {};

  