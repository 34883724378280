import React from 'react'
import { InlineMath } from 'react-katex';

export const UCT = () => {
  return (
    <div>
      <p style={{ width: '100%', textAlign: 'center' }}>
        <InlineMath math="\dfrac{w_i}{n_i} + \sqrt{\dfrac{2 \ln{n_p}}{n_i}}"/>
      </p>
      <p>
      (<InlineMath math="n_p"/> is the number of times the parent
      of <i>i</i> has been visited)
      </p>
    </div>
  );
};

export const Description = () => {
  return (
    <p>
      <InlineMath math="{n_i}"/> = number of times we visited this node
      <br/>
      <InlineMath math="{w_i}"/> = value / reward from the perspective of the player that just moved
    </p>
  );
};

export const Ni =() => <InlineMath math="{n_i}"/>;
export const Np =() => <InlineMath math="{n_p}"/>;
export const Wi =() => <InlineMath math="{w_i}"/>;
