import React from 'react';
import { Board } from './board';
import Visualization from './visualization';
import { IsVictory, IsTerminal } from './utils';
import {parse, stringify} from 'flatted/esm';

function Node(cells, parent) {
  let player = true;
  if (parent) {
    player = !parent.player;
  }

  let future = [];
  for (let i = 0; i < 9; i++) {
    if (cells[i] === ' ') {
      future.push(i);
    }
  }

  return {
    cells,
    future,
    children: [],
    name: 't',
    parent,
    n: 0,
    w: 0,
    player,
  };
}

function Simulate(node, player = 'X') {
  for (let i = 0; i < 9; i++) {
    if (node.cells[i] === ' ') {
      const cells = node.cells.substring(0, i) + player + node.cells.substring(i+1);
      const child = Node(cells, node);
      node.children.push(child);
    }
  }

  for (let child of node.children) {
    Simulate(child, player === 'X' ? 'O' : 'X');
  }
}

function Select(node) {
  while (true) {
    node.dark = true;

    if (node.future.length > 0) {
      return node;
    }

    if (node.children.length == 0) {
      return node;
    }

    let best = 0;
    let bestNode = null;
    for (let child of node.children) {
      const uct = child.w / child.n + Math.sqrt(2 * Math.log(node.n) / child.n);
      if (uct > best || bestNode === null) {
        bestNode = child;
        best = uct;
      }
    }

    node = bestNode;
  }
}

function Expand(node) {
  let child = node;
  for (const i of node.future) {
    const symbol = node.player ? 'X' : 'O';
    const cells = node.cells.substring(0, i) + symbol + node.cells.substring(i+1);
    child = Node(cells, node);
    child.highlight = [i];
    node.children.push(child);
    node.future = node.future.slice(1);
    break;
  }
  return child;
}

function Backprop(root, result, current) {
  while (current !== null) {
    current.highlightScore = true;
    current.n++;

    if (current.player !== root.player &&
        result.winner === 'X') {
      current.w += 1;
    }

    current = current.parent;
  }
}

function Playout(node) {
  let cells = node.cells;
  let player = node.player;

  const positions = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6]
  ];

  while (true) {
    const v = IsVictory(cells);
    if (v !== undefined) {
      return { ...v, cells };
    }

    if (IsTerminal(cells)) {
      return { draw: true, cells };
    }

    let id = null;
    for (let pos of positions) {
      let a = 0;
      let b = 0;
      id = null;

      for (let i of pos) {
        if (cells[i] === 'O') {
          a++;
        }
        if (cells[i] === 'X') {
          b++;
        }
        if (cells[i] === ' ') {
          id = i;
        }
      }

      if ((a == 2 || b == 2) && id !== null) {
        break;
      }
    }

    const symbol = player ? 'X' : 'O';

    if (id !== null) {
      cells = cells.substring(0, id) + symbol + cells.substring(id+1);
    } else {
      let empty = [];
      for (let i = 0; i < 9; i++) {
        if (cells[i] === ' ') {
          empty.push(i);
        }
      }
      id = empty[Math.floor(Math.random() * empty.length)];
      cells = cells.substring(0, id) + symbol + cells.substring(id+1);
    }

    player = !player;
  }
}

function Cleanup(node) {
  node.highlightScore = false;
  node.dark = false;
  for (const child of node.children) {
    Cleanup(child);
  }
}

const SELECTION = 0;
const EXPANSION = 1;
const PLAYOUT = 2;
const BACKPROP = 3;
const CLEANUP = 4;

export default class MCTS extends React.Component {
  constructor(props) {
    super(props);

    this.root = Node('OOXX O  X', null);
    this.state = {
      latest: this.root,
      phase: SELECTION,
    };

    this.step = this.step.bind(this);
    this.fast = this.fast.bind(this);
  }

  step() {
    switch (this.state.phase) {
      case SELECTION: {
        const node = Select(this.root);
        this.setState({
          phase: EXPANSION,
          first: false,
          node,
        });
        break;
      }

      case EXPANSION: {
        Cleanup(this.root);
        const node = Expand(this.state.node);
        node.dark = true;
        this.setState({
          phase: PLAYOUT,
          node,
        });
        break;
      }

      case PLAYOUT: {
        const result = Playout(this.state.node);

        const cells = this.state.node.cells;
        this.state.node.cells = result.cells;

        this.setState({
          phase: BACKPROP,
          result,
          cells,
        });
        break;
      }

      case BACKPROP: {
        this.state.node.cells = this.state.cells;
        this.state.node.dark = false;
        Backprop(this.root, this.state.result, this.state.node);

        this.setState({
          phase: CLEANUP,
        });
        break;
      }

      case CLEANUP: {
        Cleanup(this.root);
        this.setState({
          phase: SELECTION,
        });
        break;
      }
    }

    const latest = parse(stringify(this.root));
    this.setState({ latest });
  }

  fast() {
    for (let i = 0; i < 100; i++) {
      const node = Select(this.root);
      const child = Expand(node);
      const result = Playout(child);
      Backprop(this.root, result, child);
    }
    Cleanup(this.root);
  }

  render() {
    const phaseMap = {
      [SELECTION]: "",
      [EXPANSION]: "Selection Phase",
      [PLAYOUT]: "Expansion Phase",
      [BACKPROP]: "Playout Result: ",
      [CLEANUP]: "Backpropagating Results",
    };

    let message = phaseMap[this.state.phase];

    if (this.state.phase == BACKPROP) {
      if (this.state.result.winner) {
        message += this.state.result.winner + ' wins!';
      }

      if (this.state.result.draw) {
        message += 'Draw';
      }
    }

    message = <div style={{ height: 50, marginTop: 10, width: '100%', textAlign: 'center' }}>{message}</div>;

    return (
      <div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <button onClick={this.step}>step</button>
        </div>
        {message}
        <Visualization
          disableAnimation={true}
          nodes={this.state.latest}/>
      </div>
    );
  }
}
